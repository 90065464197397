import axios from "@/utils/request";


// 帖子发现列表
export function queryCommunityList(data) {
    return axios.get(`vid/community/list`, {
        params: data
    })
}

// 帖子关注列表
export function queryFollowDynamicsList(data) {
    return axios.get(`follow/dynamics/list`, {
        params: data
    })
}

// 获取帖子详情
export function queryVidInfo(data) {
    return axios.get(`vid/info`, {
        params: data
    })
}


// 获取热门话题列表
export function queryHotspotWonderList(data) {
    return axios.get(`hotspot/wonder/list`, {
        params: data
    })
}

// 获取官方博主列表
export function queryRecommendUserList(data) {
    return axios.get(`recommend/user/list`, {
        params: data
    })
}


// 获取我关注的话题
export function queryMineCollectInfoList(data) {
    return axios.get(`mine/collect/infoList`, {
        params: data
    })
}

// 发布作品
export function releaseVidSubmit(data) {
    return axios.post(`vid/submit`, data)
}


// 用话题获取帖子列表
export function queryCommunityTagList(data) {
    return axios.get(`vid/community/tag/list`, {
        params: data
    })
}

//获取标签详情
export function queryTagInfo(data) {
    return axios.get(`tag/info`, {
        params: data
    })
}

// 关注/取消关注 话题
export function collectTag(data) {
    return axios.post(`mine/collect`, data)
}












