<template>
    <div class="releasePage">
        <div class="headBar">
            <div class="leftIcon" @click="$router.go(-1)">
                <img src="@/assets/png/left.png">
            </div>
            <div class="headTitle" @click="submit">
                <span>发布</span>
            </div>
        </div>
        
        <div class="title">
            <van-field
                v-model="titleValue"
                rows="7"
                autosize
                type="textarea"
                maxlength="255"
                placeholder="请输入标题（至少三个字）～
最多字是255个字"
                show-word-limit
            />
        </div>
        
        <div class="addContent">
            <div class="titleName">
                <span>添加内容</span>
            </div>
            <div class="uploadContent">
<!--                <div class="upload">-->
<!--                    <img src="@/assets/png/uploadAdd.png">-->
<!--                </div>-->
                <UploaderFile @uploadSuccess="uploadSuccess" @delImg="delImg"></UploaderFile>
<!--                <input class="file" ref="file" type="file" @change="fileChang">-->
            </div>
        </div>
        
        <div class="topicContent">
            <div class="titleName">
                <span>选择话题</span>
            </div>
            <div class="topicTags">
                
                <div class="topicTagItem" v-for="(item,index) in tagsValue" :key="index">
                    <span>#{{item.name}}</span>
                    <img class="tagClose" src="@/assets/png/tagClose.png" @click="delTag(index)">
                </div>
                
                <div class="addTag" @click="addTag">
                    <img src="@/assets/png/uploadAdd.png">
                </div>
            </div>
        </div>
       
        
    </div>
</template>

<script>

import {releaseVidSubmit} from "@/api/community";

import UploaderFile from "@/components/UploaderFile";

export default {
  name: "ReleasePage",
    components:{
        UploaderFile
    },
    data(){
      return {
          titleValue: '',
          tagsValue:[
              {
                  name:"三个字"
              },
              {
                  name:"三个字"
              },
              {
                  name:"三个字"
              },
              {
                  name:"三个字"
              },
              {
                  name:"三个字"
              },
              {
                  name:"三个字"
              }
          ],
          imgList:[]
      }
    },methods:{
        addTag(){
            this.tagsValue.push({
                name:"三个字"
            });
        },delTag(){
            
        },
        // 发布
        async submit() {
            if(!this.titleValue) {
                this.$toast({
                    message: '请输入标题内容',
                    position: 'top',
                });
                return;
            }
            let req = {
                title: this.titleValue,
                newsType: "COVER",
                tags:["5dbeb22fe76468ea20625ebd"],
                seriesCover: this.imgList,
                sourceURL:"",
            }

            let res = await this.$Api(releaseVidSubmit, req);
        },
        // 上传成功回调
        uploadSuccess(imgUrl){
            this.imgList.push(imgUrl);
        },
        // 图片删除回调
        delImg(index) {
            this.imgList.splice(index, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
    .releasePage{
        
        .headBar{
            display: flex;
            height: 44px;
            align-items: center;

            .leftIcon{
                padding: 0 12px;
                img{
                    width: 17px;
                    height: 17px;
                }
            }

            .headTitle{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 12px;
                font-size: 15px;
                color: rgb(36,248,217);
            }
        }
        
        .title{
            margin-top: 12px;
            
            /deep/ .van-cell{
                background-color: rgb(27,22,76);
                font-size:15px;
            }

            /deep/ .van-field__control{
                color: rgb(93,100,114);
            }

            input::input-placeholder{color:  red;}
            ::-webkit-input-placeholder { /* WebKit browsers */
                color: rgb(93,100,114);
            }
            :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: rgb(93,100,114);
            }
            ::-moz-placeholder { /* Mozilla Firefox 19  */
                color: rgb(93,100,114);
            }
            :-ms-input-placeholder { /* Internet Explorer 10  */
                color: rgb(93,100,114);
            }
            
        }
        
        .addContent{
            padding: 18px 12px 18px 12px;
            
            .titleName{
                font-size: 18px;
                color: #000;
                font-weight: 500;
            }
            
            .uploadContent{
                margin-top: 12px;
                
                .upload{
                    width: 105px;
                    height: 105px;
                    background: rgb(27,22,76);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 6px;
                    
                    img{
                        width: 33px;
                        height: 33px;
                    }
                }
                
                .file{
                    opacity: 0;
                    width: 105px;
                    height: 105px;
                    position: absolute;
                    margin-top: -105px;
                }
            }
        }

        .topicContent{
            padding: 0 12px;

            .titleName{
                font-size: 18px;
                color: #000;
                font-weight: 500;
            }
            
            .topicTags{
                display: flex;
                font-size: 12px;
                color: rgb(160,157,184);
                margin-top: 20px;
                flex-wrap: wrap;
                
                .topicTagItem{
                    margin-right: 18px;
                    margin-top: 18px;
                    padding: 2px 6px;
                    border-radius: 6px;
                    background: rgb(27,22,76);
                    
                    .tagClose{
                        width: 12px;
                        height: 12px;
                        margin-top: -5px;
                        margin-left: 1px;
                        position: absolute;
                    }
                }
                
                .addTag{
                    width: 21px;
                    height: 21px;
                    background: rgb(27,22,76);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 6px;
                    margin-top: 18px;
                    
                    img{
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
        
    }
</style>